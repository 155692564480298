//  Core

.react-joyride__overlay {
  z-index: 1200 !important;
  display: block !important;

  .react-joyride__spotlight {
    z-index: 1300 !important;
    display: block !important;
  }
}

.react-joyride__beacon {
  outline: none !important;
}

.__floater {
  z-index: 1400 !important;
}

.__floater__body {
  button {
    @include border-radius($border-radius !important);
    outline: none !important;
  }
}

.react-joyride__tooltip {
  box-shadow: $box-shadow-xxl !important;
  @include border-radius($border-radius-lg !important);

  h4 {
    color: $black  !important;
    font-weight: bold  !important;
    font-size: $font-size-lg  !important;
  }

  div button {
    background: $primary;
    color: $white;
  }
}

.joyride-button {
  @include border-radius($btn-border-radius !important);
}

.joyride-step__container {
  @include border-radius($card-border-radius !important);
  box-shadow: $modal-content-box-shadow-xs !important;
}
